const SIDEBAR_TRANSITION_TIME = 200;

const DISMISS_SELECTOR = '[data-dismiss="sidebar"]';
const BACKDROP_SELECTOR = '.sidebar > .sidebar-backdrop';

const show_sidebar = (sidebar_el) => {
    sidebar_el.style.display = 'block';
    sidebar_el.classList.add('show');
    sidebar_el.setAttribute('aria-hidden', false);
    sidebar_el.setAttribute('aria-role', 'dialog');
    sidebar_el.setAttribute('aria-modal', true);
    sidebar_el.setAttribute('tabindex', -1);
}

const hide_sidebar = (sidebar_el, instant) => {
    if (!instant) {
        setTimeout(() => {
            sidebar_el.style.display = 'none';
        }, SIDEBAR_TRANSITION_TIME);
    } else {
        sidebar_el.style.display = 'none';
    }

    sidebar_el.classList.remove('show');
    sidebar_el.setAttribute('aria-hidden', true);
    sidebar_el.removeAttribute('aria-role');
    sidebar_el.removeAttribute('aria-modal');
    sidebar_el.setAttribute('tabindex', -1);
}

const push_elements = [];
[...document.querySelectorAll('.pushable > .push')].forEach(el => {
    push_elements.push({
        el: el,
        stickyBottom: el.querySelector('.sticky-bottom'),
        stickyTop: el.querySelector('.sticky-top'),
    });
});
const editor_container_elements = [];
[...document.querySelectorAll('[data-editor-container]')].forEach(el => {
    editor_container_elements.push({
        el: el,
        staticMenu: el.querySelector('.editor-static-menu'),
    });
});
const table_container_elements = [];
[...document.querySelectorAll('.table-container')].forEach(el => {
    table_container_elements.push({
        el: el,
        numberOfColumns: [...el.querySelectorAll('table thead tr > th')].length || 1,
    });
});
const onWindowResize = (e) => {
    push_elements.forEach(push => {
        push.el.style.setProperty('--offset-width', `${push.el.offsetWidth}px`);
        push.el.style.setProperty('--offset-height', `${push.el.offsetHeight}px`);
        push.el.style.setProperty('--sticky-bottom-offset-height', `${push.stickyBottom?.offsetHeight ?? 0}px`);
        push.el.style.setProperty('--sticky-top-offset-height', `${push.stickyTop?.offsetHeight ?? 0}px`);
    });
    editor_container_elements.forEach(editor => {
        editor.el.style.setProperty('--editor-static-menu-offset-height', `${editor.staticMenu?.offsetHeight ?? 0}px`);
    });
    table_container_elements.forEach(table_container => {
        table_container.el.style.setProperty('--table-container-offset-width', `${table_container.el.offsetWidth}px`);
        table_container.el.style.setProperty('--table-container-number-of-columns', `${((table_container.numberOfColumns ?? 1) - 1) || 1}`);
    });
};

[...document.querySelectorAll('.sidebar:not(.show)')].forEach(sidebar_el => {
    hide_sidebar(sidebar_el, true);
});
[...document.querySelectorAll('.sidebar.show')].forEach(sidebar_el => {
    show_sidebar(sidebar_el);
});

[...document.querySelectorAll('[data-toggle="sidebar"]')].forEach(toggle_el => {
    toggle_el.onclick = (e) => {
        const btn = e.currentTarget;
        const targetSelector = btn.dataset.target;
        const sidebar = document.querySelector(targetSelector);
        let visible = sidebar.classList.contains('show');

        if (visible) {
            hide_sidebar(sidebar);
        } else {
            show_sidebar(sidebar);
        }
    };
});

[...document.querySelectorAll(DISMISS_SELECTOR)].forEach(dismiss_el => {
    dismiss_el.onclick = (e) => {
        const btn = e.currentTarget;
        const sidebar = btn.closest('.sidebar');

        hide_sidebar(sidebar);
    };
});
[...document.querySelectorAll(BACKDROP_SELECTOR)].forEach(backdrop_el => {
    backdrop_el.onclick = (e) => {
        const backdrop = e.currentTarget;
        const sidebar = backdrop.closest('.sidebar');

        hide_sidebar(sidebar);
    };
});

onWindowResize();
addEventListener('resize', onWindowResize);
