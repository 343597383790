(()=>{
    const profileForm = document.getElementById('profileForm');
    if(profileForm) {
        const organizationExpandedFields = profileForm.querySelector('.org_expanded_data');

        const showOrganizationData = () => {
            let organizationFieldSelected = profileForm.querySelector("[name=is_organization]:checked");
            if (organizationFieldSelected.value == 1) {
                organizationExpandedFields.style.display = 'block';
            } else {
                organizationExpandedFields.style.display = 'none';
            }
        }

        showOrganizationData();
        profileForm.addEventListener('change', showOrganizationData);
    }
    const passwordToggle = document.getElementsByClassName('togglePassword');

    if(passwordToggle.length)
    {
        [...passwordToggle].forEach(passwdBtn=>{
            passwdBtn.addEventListener('click', function(){
                let siblings = this.getElementsByTagName('svg');
                [...siblings].forEach(image => {
                    image.classList.toggle('d-none');
                });
                let inputGroup = passwdBtn.closest('.input-group');
                let input = inputGroup.querySelector('input');
                let type = input.getAttribute('type') === 'password'?'text':'password';
                input.setAttribute('type', type);
            })

        })
    }
})();
